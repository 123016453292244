import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueCookies from 'vue3-cookies'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import './custom-vue3-video-player.css'
import MasonryWall from '@yeger/vue-masonry-wall'

const lang = {
  dashboard: {
    btn: {
      play: 'Lecture',
      pause: 'Pause',
      fullscreen: 'Plein écran',
      exitFullscreen: 'Sortir du mode plein écran',
      mute: 'Muet',
      unmute: 'Son',
      back: 'Retour',
      pip: 'Pip'
    },
    settings: {
      autoplay: 'Lecture auto',
      loop: 'Lecture en boucle',
      speed: 'Vitesse',
      resolution: 'Résolution'
    }
  },
  layers: {
    error: {
      title: 'Error!',
      2404: 'Video Source Undefined',
      2502: 'Media Network Error',
      2503: 'Video Cannot DECODE',
      2504: 'Video Cannot Play!',
      601: 'Live video Cannot Play!'
    },
    loading: {
      msg: 'Chargement ...'
    }
  }
}

const app = createApp(App)
installElementPlus(app)
app.use(MasonryWall)
app.use(VueCookies, { expireTimes: '30d', path: '/' })
app.use(Vue3VideoPlayer, {
  lang
})
app.use(VueGoogleMaps, { load: { key: 'AIzaSyAbXf04Jd7y-JKdIyygiyNcTVDBsrhNFJk' } })
app.use(store).use(router).mount('#app')
