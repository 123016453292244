export default {
  addClient (state, client) {
    state.clients.push(client)
  },
  updateClient (state, client) {
    for (let i = 0; i < state.clients.length; i++) {
      if (state.clients[i].id === client.id) {
        state.clients[i] = client
      }
    }
  },
  deleteClient (state, clientId) {
    state.clients = state.clients.filter((c) => c.id !== clientId)
  }
}
