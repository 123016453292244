<template>
  <div id="menaces" v-if="category">
    <div class="checkboxs">
      <el-checkbox-group v-model="localMenaces">
        <el-checkbox :label="+i" v-for="(menace, i) in menaces" :key="i">{{menace}}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="save-button"  v-if="menacesChange">
      <el-button type="primary" @click="saveMenaces">Enregistrer</el-button>
      <el-button type="danger" @click="cancel">Annuler</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      default: null
    }
  },
  watch: {
    category (val, old) {
      this.localMenaces = val.menace.map((m) => m[1])
    },
    localMenaces (val) {
      let menacesChange = false
      if (val.length > 0) {
        for (let i = 0; i < this.category.menace.length; i++) {
          if (!val.includes(this.category.menace[i][1]) || val.length !== this.category.menace.length) {
            menacesChange = true
          }
        }
      } else if (val.length !== this.category.menace.length) {
        menacesChange = true
      }
      this.menacesChange = menacesChange
    }
  },
  data () {
    return {
      localMenaces: [],
      menaces: {
        1: 'Pollution marine',
        2: 'Prédation',
        3: 'Changement climatique',
        4: 'Destruction habitats',
        5: 'Capture accidentelle',
        6: 'Capture illégale',
        7: 'Collisions',
        8: 'Cycle de reproduction long',
        9: 'Parasitisme et épizootie',
        10: 'Eoliennes en mer'
      },
      menacesChange: false
    }
  },
  methods: {
    saveMenaces () {
      this.$confirm(
        'Êtes-vous sûr de vouloir enregistrer ?',
        'Attention',
        {
          confirmButtonText: 'Valider',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          const menace = this.localMenaces.map((m) => [this.menaces[m], m])
          this.$store.dispatch('updateCategory', { ...this.category, menace })
            .then(() => {
              this.menacesChange = false
            })
            .catch((err) => {
              if (err.message.toString().match(/409/)) {
                this.$notify.error({
                  title: 'Attention',
                  message: 'Impossible d\'enregistrer la catégorie'
                })
              }
            })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    cancel () {
      this.localMenaces = this.category.menace.map((m) => m[1])
    }
  }
}
</script>

<style lang='scss' scoped>
#menaces {
  width: 100%;
  .checkboxs{
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    -webkit-box-shadow: 4px 4px 8px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 8px 1px rgba(0, 0, 0, 0.1);
    .el-checkbox-group {
      display: flex;
      flex-direction: column;
    }
  }
  .save-button {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
