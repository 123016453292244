const Vue = require('vue')

export default {
  getCategories ({ commit }) {
    return Vue.api.get('/categories').then((res) => commit('init', { categories: res.data }))
  },
  updateCategory ({ commit }, category) {
    return Vue.api.put('/categories/' + category.group, category).then((res) => commit('updateCategory', res.data))
  }
}
