<template>
  <el-container direction="vertical">
    <h1>Médias ({{ medias.length }})</h1>
    <div class="search" v-if="false">
      <el-input
        class="searchbar"
        type="text"
        placeholder="Recherche"
        v-model="search"
      />
    </div>
    <div id="medias-cards" v-if="medias.length > 0">
      <el-card class="media-card" v-for="item in medias" :key="item.id">
        <el-image @click="$router.push('/observations/' + item.associatedId)" class="media-item" v-if="item.extension !== 'mp4'" :src="obsMediaPath({id: item.mediaId, ext: item.extension})" lazy />
        <div class="media-item video-container" v-else @click="playerAction(obsMediaPath({id: item.mediaId, ext: item.extension}), true, '/observations/' + item.associatedId, item.mediaId)">
          <el-icon class="el-icon-video-play"/>
        </div>
        <div class="infos">
          <p>{{ item.createdAt ? moment.unix(item.createdAt).format('DD/MM/YYYY') : 'Pas de date' }}</p>
          <p>{{ item.observation && item.observation.boatName ? item.observation.boatName : 'Pas de bateau' }}</p>
        </div>
      </el-card>
    </div>
    <p class="add-media" v-else-if="loading"><span>Chargement...</span></p>
    <p class="add-media" v-else><span>Aucun média</span></p>
    <el-dialog
      v-model="openPlayer"
      width="800px"
      @close="playerAction()"
    >
      <vue3-video-player :src="playerLink" />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="$router.push(this.playerObsLink)">Voir l'observation</el-button>
          <el-button @click="download()" type="primary" icon="el-icon-download">Télécharger</el-button>
        </span>
    </template>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data () {
    return {
      moment,
      search: '',
      medias: [],
      openPlayer: false,
      loading: false,
      playerObsLink: null,
      downloadMediaId: null
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.loadData()
      }
    }
  },
  computed: {
    filteredPics () {
      return this.photos.filter((item) => item.category.match(this.search) || item.account_name.match(this.search) || item.id.match(this.search))
    },
    ...mapState(['user', 'token'])
  },
  methods: {
    loadData () {
      this.loading = true
      this.$store.dispatch('getObservationsMedias')
        .then((medias) => {
          this.medias = medias
          this.loading = false
        })
        .catch((err) => {
          this.$notify({
            title: 'Erreur',
            message: 'Impossible de charger les médias',
            type: 'error'
          })
          console.error(err)
          this.loading = false
        })
    },
    obsMediaPath (el) {
      if (el) {
        return `${process.env.VUE_APP_API}/observations/media/${el.id}.${el.ext}?token=${this.token}`
      }
      return ''
    },
    playerAction (link = null, visible = false, obsLink = null, mediaId = null) {
      if (link && visible) {
        this.playerLink = link
        this.openPlayer = visible
        this.playerObsLink = obsLink
        this.downloadMediaId = mediaId
      } else {
        this.playerObsLink = null
        this.playerLink = null
        this.openPlayer = false
        this.downloadMediaId = null
      }
    },
    download () {
      if (this.downloadMediaId) {
        window.open(`${process.env.VUE_APP_API}/observations/media/${this.downloadMediaId}/download?token=${this.token}`, '_blank')
      }
    }
  },
  mounted () {
    if (this.user) {
      this.loadData()
    }
  }
}
</script>

<style scoped lang="scss">
  .el-container {
    .search {
      display: flex;
      justify-content: center;
      .searchbar {
        width: 50%;
      }
    }
    .medias-items {
      width: 100%;
    }

    .media-card {
      margin: 10px;
      width: 200px;
      height: 200px;
      .el-image {
        cursor: pointer;
        height: 100%;
      }
    }
    #medias-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .media-card {
        width: 30%;
        height: fit-content;
        .video-container {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: lightgrey;
          width: 100%;
          aspect-ratio: 1;
          .el-icon-video-play {
            font-size: 50px;
          }
        }
        .infos {
          padding: 20px 20px 0px;
          display: flex;
          justify-content: space-between;
          p {
            margin: 0;
            font-size: 14px;
            color: grey;
          }
        }
      }
    }

    .masonry-item {
      height: fit-content;
      .video-container {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: lightgrey;
        height: 200px;
        .el-icon-video-play {
          font-size: 50px;
        }
      }
    }
    .add-media {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
    }
  }

</style>

<style lang="scss">
  #medias-cards {
    .media-card {
      .el-card__body {
        padding: 0 0 25px 0;
        .el-image {
          width: 100%;
          max-height: 430px;
          .el-image__error {
            width: 100%;
            aspect-ratio: 1;
          }
        }
      }
    }
  }
</style>
