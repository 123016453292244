<template>
  <el-container direction="vertical" id="clients">
    <h1>Utilisateurs</h1>
    <div class="searchandadd">
      <el-input
        class="searchbar"
        type="text"
        placeholder="Recherche"
        clearable
        v-model="search"
        />
      <el-button
        v-if="canEdit"
        type="primary"
        icon="el-icon-circle-plus"
        @click="addClient"
      >
        Ajouter
      </el-button>
    </div>
    <AddClient v-model:visible="visible" :client="client"  />

    <el-table
      :data="filteredAccount"
      :row-class-name="rowClassName"
      :default-sort="{ prop: 'status', order: 'ascending' }"
      style="width: 100%"
    >
      <el-table-column
        prop="email"
        label="Email"
        width="200"
      />
      <el-table-column prop="firstName" label="Prénom" />
      <el-table-column
        prop="lastName"
        label="Nom"
        sortable
        width="100"
        column-key="lastName"
      />
      <el-table-column
        prop="phone"
        label="Téléphone"
        width="150"
      >
        <template #default="scope">
          <span v-if="scope.row.phone">{{ scope.row.phone }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="accountType"
        label="Type de compte"
        width="180px"
        sortable
      >
        <template #default="scope">
          <el-tag v-if="scope.row.accountType === '1'">Pêcheur loisir</el-tag>
          <el-tag type="info" v-if="scope.row.accountType === '2'">Naturaliste</el-tag>
          <el-tag type="success" v-if="scope.row.accountType === '3'">Pêcheur pro</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="Status"
        sortable width="130"
      >
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.status === '0'">Temporaire</el-tag>
          <el-tag v-else type="success">Actif</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="250" align="right">
        <template #default="scope">
          <el-button
            type="warning"
            size="small"
            v-if="scope.row.status === '0'"
            @click="rowValidate(scope.row)"
          >
            Valider
          </el-button>
          <el-button
            :class="{'el-icon-edit': canEdit, 'el-icon-view': !canEdit}"
            size="small"
            @click="handleEdit(scope.row)"
          />
          <el-button
            v-if="canEdit"
            class="el-icon-delete"
            type="danger"
            size="small"
            @click="handleDelete(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import AddClient from './AddClient.vue'
export default {
  components: {
    AddClient
  },
  data () {
    return {
      client: null,
      visible: false,
      search: ''
    }
  },
  methods: {
    addClient () {
      this.client = null
      this.visible = true
    },
    handleEdit (row) {
      this.client = row
      this.visible = true
    },
    handleDelete (row) {
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer ce client ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteClient', row.id)
            .then(() => {
              this.$store.commit('deleteClient', row.id)
            })
        })
        .catch((err) => {
          this.$notify({
            title: 'Erreur',
            message: 'Cet utilisateur n\'a pas pu être supprimé',
            type: 'error'
          })
          console.error(err)
        })
    },
    rowClassName ({ row }) {
      if (row.status === '0') {
        return 'pending'
      }
    },
    rowValidate (row) {
      this.$store.dispatch('updateClient', { ...row, status: '1' })
        .then((client) => {
          this.$store.commit('updateClient', client)
        })
        .catch((err) => {
          this.$notify({
            title: 'Erreur',
            message: 'Cet utilisateur n\'a pas pu être modifié',
            type: 'error'
          })
          console.error(err)
        })
    }
  },
  computed: {
    filteredAccount () {
      return this.clients.filter((c) => {
        if (this.search.length === 0) {
          return true
        }
        return (c.email && c.email.toLowerCase().match(this.search.toLowerCase())) || (c.firstName && c.firstName.toLowerCase().match(this.search.toLowerCase())) || (c.lastName && c.lastName.toLowerCase().match(this.search.toLowerCase())) || (c.phone && c.phone.toLowerCase().match(this.search.toLowerCase()))
      })
    },
    canEdit () {
      return this.user && this.user.role === '1'
    },
    ...mapState(['clients', 'user'])
  }
}
</script>

<style scoped lang="scss">
  .el-container {
    .searchandadd {
      display: flex;
      justify-content: space-between;
      .searchbar {
        width: 50%;
      }
    }
  }
</style>
<style lang="scss">
  #clients {
    .el-table .pending {
      background: oldlace;
    }
  }
</style>
