export default {
  addSpecies (state, species) {
    state.species.push(species)
  },
  updateSpecies (state, s) {
    for (let i = 0; i < state.species.length; i++) {
      if (state.species[i].id === s.id) {
        state.species[i] = s
      }
    }
  }
}
