<template>
  <el-container direction="vertical" id="history">
    <h1>Historique</h1>
    <div class="search">
      <el-input
        class="searchbar"
        type="text"
        placeholder="Recherche"
        v-model="search"
        clearable
        @clear="resetPage"
      />
        <el-button
          class="addbtn"
          type="primary"
          @click="resetPage"
        > Rechercher
        </el-button>
    </div>
    {{history.client}}

    <div class="table-container" v-if="history.length > 0">
      <el-table
        v-loading="loadingData"
        :data="history"
        :default-sort="{ prop: 'status', order: 'ascending' }"
        style="width: 100%"
      >
        <el-table-column
          prop="login"
          label="Login"
          sortable width="180"
        />
        <el-table-column
          prop="date"
          label="Date"
          width="190"
          sortable
        >
          <template #default="scope">
            {{ datetime(scope.row.date, 'DD/MM/YYYY  HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="action"
          label="Action"
          width="190"
          sortable
        >
        <template #default="scope">
          <span>{{scope.row.action + ' ' + scope.row.element}}</span>
        </template>
        </el-table-column>
        <el-table-column label="Compte">
          <template #default="scope">
            <span v-if="scope.row.client && scope.row.client.firstName && scope.row.client.lastName && scope.row.client.email">{{ scope.row.client.firstName + ' ' + scope.row.client.lastName +  ' (' + scope.row.client.email + ')'}}</span>
            <span v-else-if="scope.row.client && scope.row.client.id">{{ getClientName(scope.row.client.id) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Element">
          <template #default="scope">
            <template v-if="scope.row.element === 'observation'">
                <el-link v-if="scope.row.action !=='Suppression' && scope.row.requestBody && scope.row.requestBody.id" @click.prevent="$router.push({ name: 'AddObservation', params:{id: scope.row.requestBody.id} })">Observation {{ getInfos(scope.row) }}</el-link>
                <span v-else>Observation {{ getInfos(scope.row) }}</span>
            </template>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-loading="loadingData"
        v-model:currentPage="page"
        :page-size="25"
        :total="count"
        @current-change="pageChange"
        background
        layout="prev, pager, next"
      />
    </div>
    <template v-else-if="history.length < 1 && loadingData">
      <p class="table-message">Chargement...</p>
    </template>
    <template v-else>
      <p class="table-message">Aucune donnée</p>
    </template>
  </el-container>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      history: [],
      count: null,
      search: '',
      page: 1,
      loadingData: false
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.load(this.page)
      }
    }
  },
  computed: {
    ...mapState(['user', 'clients', 'species'])
  },
  methods: {
    datetime (date, format) {
      if (typeof date === 'number') {
        return moment.unix(date).format(format)
      } else if (date.length === 10) {
        if (date.replace(/-/g, '').length === 8) {
          return moment(date, 'DD-MM-YYYY').format(format)
        } else if (date.replace(/-/g, '').length === 10) {
          return moment.unix(parseInt(date), 'DD/MM/YYYY').format(format)
        }
      }
      return moment(date).format(format)
    },
    resetPage () {
      this.page = 1
      this.load(1)
    },
    pageChange (newPage) {
      this.load(newPage)
    },
    load (page) {
      this.loadingData = true
      this.$store.dispatch('getHistory', { search: this.search, page }).then((res) => {
        this.history = res.history
        this.count = res.count
        this.loadingData = false
      }).catch((err) => {
        this.loadingData = false
        this.$notify({
          title: 'Erreur',
          message: 'Ces données n\'ont pas pu être chargées',
          type: 'error'
        })
        console.error(err)
      })
    },
    getClientName (clientId) {
      const client = this.clients.find((c) => c.id === clientId)
      return client ? client.firstName + ' ' + client.lastName + ' ' + '(' + client.email + ')' : '-'
    },
    getEspece (especeId, otherEspece) {
      let espece = otherEspece
      if (especeId) {
        const tempEsp = this.species.find((e) => e.id === especeId)
        espece = tempEsp.name.split('(')[0]
      }
      return espece
    },
    getInfos (row) {
      const body = row.requestBody || null
      let espece = null
      let date = null
      if (body) {
        espece = this.getEspece(body.especeId, body.otherEspece)
        date = body.obsAt ? this.datetime(body.obsAt, 'DD/MM/YYYY') : null
      }
      return `${espece ? ' - ' + espece : ''} ${date ? ' du ' + date : ''}`
    }
  },
  mounted () {
    if (this.user) {
      this.load(this.page)
    }
  }
}
</script>

<style scoped lang="scss">
    .search {
      display: flex;
      justify-content: space-between;
      .searchbar {
        width: 50%;
      }
    }
    .el-pagination {
      padding: 1rem;
      text-align: center;
    }
</style>
