<template>
  <el-dialog
    :model-value="open"
    title="Ajouter une image"
    @close="$emit('update:open', false)"
  >
    <el-upload
      id="upload"
      drag
      :action="uploadAction"
      :headers="headers"
      :on-success="addedFile"
    >
      <el-icon class="el-icon-upload" />
      <div class="el-upload__text">
        Déposer le fichier <em>ou cliquer ici</em>
      </div>
      <template #tip>
        <div class="el-upload__tip">
          une image jpg/png
        </div>
      </template>
    </el-upload>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default: ''
    }
  },

  emits: ['uploaded'],
  data () {
    return {}
  },
  computed: {
    uploadAction () {
      return process.env.VUE_APP_API ? process.env.VUE_APP_API + this.path : ''
    },
    headers () {
      return { ...axios.defaults.headers.common, Authorization: 'Bearer ' + this.token }
    },
    ...mapState(['token'])
  },
  methods: {
    addedFile (val, res) {
      if (res.status === 'success') {
        this.$emit('uploaded', true)
      }
    }
  }
}
</script>

<style lang='scss'>
#upload {
  .el-upload-dragger,  .el-upload.el-upload--text {
    width: 100%!important;
  }
}
</style>
