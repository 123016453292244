<template>
  <div>
    <h5>Navires</h5>
    <div class="radio">
      <template v-if="filteredBoats.length > 0">
        <el-radio-group v-model="form.boat.name" v-if="canEdit" class="radio-group">
          <el-radio
            v-for="boat in filteredBoats"
            :key="boat.id"
            :value="boat.id"
            :label="boat.name"
          >
            <span class="radio-line">
              {{ boat.name }}
              <div class="buttons">
                <i class="el-icon-delete" @click="handleRemove(boat.id)"/>
                <i class="el-icon-edit" @click="handleEdit(boat)"/>
              </div>
            </span>
          </el-radio>
        </el-radio-group>
        <div v-else class="show-boats">
          <p :class="{'boat-name': true, 'selected': form.boat.name === boat.name}" v-for="(boat, i) in filteredBoats" :key="i">{{ boat.name }}</p>
        </div>
      </template>
      <span v-else class="empty">Aucun navire</span>
    </div>
    <div v-if="isHidden">
      <el-divider content-position="center" class="add-divider">{{ addBoatTitle }}</el-divider>
      <el-form>
        <el-form-item :error="error.name">
          <el-input
            v-model="form.boat.name"
            size="small"
            placeholder="Nom du navire"
          />
        </el-form-item>
        <el-form-item>
          <el-input
            size="small"
            v-model="form.boat.identification"
            placeholder="Identification"
          />
        </el-form-item>
        <el-form-item :error="error.fishing">
          <el-select
            size="small"
            v-model="form.boat.fishing"
            style="width: 100%"
            placeholder="Engin de pêche"
          >
            <el-option value="PALANGRE (LLD/LLS)">PALANGRE (LLD/LLS)</el-option>
            <el-option value="LIGNE CANNE (LHP)">LIGNE CANNE (LHP)</el-option>
            <el-option value="FILET MAILLANT (GNS)">FILET MAILLANT (GNS)</el-option>
            <el-option value="TREMAIL (GTR)">TREMAIL (GTR)</el-option>
            <el-option value="SENNE TOURNANTE (PS)">SENNE TOURNANTE (PS)</el-option>
            <el-option value="DRAGUE (DRB)">DRAGUE (DRB)</el-option>
            <el-option value="NASSE/CASIER (FPO)">NASSE/CASIER (FPO)</el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="ships" v-if="canEdit">
      <el-button
        v-if="!isHidden"
        size="small"
        icon="el-icon-circle-plus"
        class="savebtn"
        @click="addBoat"
      >
      Ajouter un autre navire
      </el-button>
      <el-button
        v-if="isHidden"
        size="small"
        class="savebtn"
        type="primary"
        @click="saveBoat(isEdittingBoat ? form.boat.id : null)"
      >
      Enregistrer le navire
      </el-button>
      <el-button
        v-if="isHidden && isEdittingBoat"
        size="small"
        class="savebtn"
        @click="removeEdit()"
      >
      Annuler
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    clientId: {
      Type: String,
      Default: null
    },
    boatName: {
      Type: String,
      Default: null
    },
    fishing: {
      Type: String,
      Default: null
    }
  },
  data () {
    return {
      filteredBoats: [],
      visible: false,
      client: null,
      isHidden: false,
      error: {
        name: null,
        fishing: null
      },
      form: {
        boat: {
          name: '',
          fishing: '',
          identification: ''
        }
      },
      addBoatTitle: 'Ajouter un navire'
    }
  },
  watch: {
    clientId (val, old) {
      if (val) {
        this.filterBoats(val)
      }
    },
    boat (value) {
      this.form.boat = value
      const boat = this.boats.find((b) => b.boat === value)
      if (boat) {
        this.form.fishing = boat.fishing
      }
    },
    fishing (value) {
      this.form.fishing = value
    },
    'form.boat.name' (value) {
      this.$emit('update:boatName', value)
    },
    'form.boat.fishing' (value) {
      this.$emit('update:fishing', value)
    },
    boats (value) {
      if (value) {
        this.filterBoats(this.clientId)
      }
    }
  },
  computed: {
    canEdit () {
      return this.user && this.user.role === '1'
    },
    ...mapState(['clients', 'boats', 'user'])
  },
  methods: {
    addBoat () {
      this.isHidden = true
      this.addBoatTitle = 'ajouter un navire'
    },
    handleEdit (boat) {
      this.addBoatTitle = 'Modifier le navire'
      this.form.boat = { ...boat }
      this.isHidden = true
      this.isEdittingBoat = true
    },
    removeEdit () {
      this.form.boat = {
        name: '',
        fishing: '',
        identification: ''
      }
      this.isHidden = false
      this.isEdittingBoat = false
    },
    saveBoat (id) {
      this.error = {
        name: null,
        fishing: null
      }

      if (!this.form.boat.fishing) {
        this.error.fishing = 'Ce champ est requis'
      }
      if (!this.form.boat.name) {
        this.error.name = 'Ce champ est requis'
      }

      if (!this.error.fishing && !this.error.name) {
        this.$store.dispatch('updateBoats', {
          clientId: this.clientId,
          boats: [
            ...this.filteredBoats.filter((b) => id ? b.id !== id : b),
            this.form.boat
          ]
        })
          .then((res) => {
            this.filterBoats(this.clientId)
            this.form.boat = {
              name: '',
              fishing: '',
              identification: ''
            }
            this.isHidden = false
          })
      }
    },
    handleRemove (boatId) {
      this.$confirm(
        'Supprimer définitivement ce navire ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteBoat', { clientId: this.clientId, boatId })
            .then(() => {
              this.filterBoats(this.clientId)
            })
        })
        .catch((err) => {
          this.$notify({
            title: 'Erreur',
            message: 'Ce navire n\'a pas pu être supprimé',
            type: 'error'
          })
          console.error(err)
        })
    },
    filterBoats (clientId) {
      this.filteredBoats = this.boats.filter((b) => b.clientId === clientId).map((b) => ({ ...b, fishing: Array.isArray(b.fishing) ? b.fishing.join(', ') : b.fishing }))
    }
  },
  mounted () {
    this.filterBoats(this.clientId)
    if (this.boatName) {
      this.form.boat.name = this.boatName
    }
  }
}
</script>

<style scoped lang="scss">
  .el-header {
    height: 100%;
    margin-top: 20px;
    background-color: #f7f7f7;
    padding: 30px;
  }
  h4 {
    margin: 0;
  }
  h4, h5 {
    text-align: center;
  }
  .ships {
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  .radio {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    .empty {
      display: block;
      width: 100%;
      text-align: center;
      font-size: 12px;
    }
  }
  .radio-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .el-radio {
    display: flex;
    width: 100%;
  }
  .radio-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .buttons {
      display: flex;
      gap: 10px;
    }
  }
  .el-icon-delete {
    color: red;
  }
  .savebtn {
    margin-top: 1rem;
  }
  .show-boats {
    display: flex;
    flex-direction: column;
    p {
      margin: 0 0 0 10px;
      display: flex;
      align-items: center;
      &:before {
        margin-right: 5px;
        content:'';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        -webkit-box-shadow:inset 0px 0px 0px 2px #000;
        -moz-box-shadow:inset 0px 0px 0px 2px #000;
        box-shadow:inset 0px 0px 0px 2px #000;
        background-color: #000;
        opacity: .3;
      }
      &.selected{
        &:before {
          -webkit-box-shadow:inset 0px 0px 0px 2px #000;
          -moz-box-shadow:inset 0px 0px 0px 2px #000;
          box-shadow:inset 0px 0px 0px 2px #000;
          background-color: #fff;
          opacity: .5;
        }
      }
    }
  }
</style>

<style lang="scss">
  .radio {
    .el-radio__label {
      width: 100%;
    }
  }
  .add-divider {
    text-align: center;
  }
</style>
