<template>
  <el-container direction="vertical">
    <h1>Carte des déclarations</h1>
    <Filters :loading="loading" @change="loadData" />
    <GMapMap
      :center="center"
      :zoom="7"
      class="map"
      v-loading="loading"
    >
      <GMapMarker
        :key="index"
        v-for="(obs, index) in observations"
        :position="this.atDock && isValidCoords(obs.position) ? obs.position : obs.location"
        :icon="getMarkerIcon(obs)"
        :clickable="true"
        @click="setInfoWindow(obs, index)"
      >
        <GMapInfoWindow
          v-if="obs.open"
          :opened="obs.open"
          :closeclick="true"
          @closeclick="observations[index].open = false"
        >
        <div class="info-window-content">
          <h3>{{ this.infoWindowContent.type}}</h3>
          <p>{{ this.infoWindowContent.espece }}<br><small>{{ this.infoWindowContent.boatName ? `${this.infoWindowContent.boatName} - ` : '' }} {{ this.infoWindowContent.client }}</small></p>
          <el-button @click="$router.push('/observations/' + obs.id)" size="small"> Editer les informations</el-button>

        </div>
        </GMapInfoWindow>
      </GMapMarker>
    </GMapMap>
  </el-container>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import Filters from './Filters'
import MarkerDefault from '../assets/icons/pin.png'
import Marker0 from '../assets/icons/pin0.png'
import Marker1 from '../assets/icons/pin1.png'
import Marker2 from '../assets/icons/pin2.png'
import Marker3 from '../assets/icons/pin3.png'
import Marker4 from '../assets/icons/pin4.png'
import Marker5 from '../assets/icons/pin5.png'

export default {
  components: { Filters },
  data () {
    return {
      moment,
      MarkerDefault,
      Marker0,
      Marker1,
      Marker2,
      Marker3,
      Marker4,
      Marker5,
      center: { lat: 43.4018, lng: 3.6966 },
      observations: [],
      infoWindowContent: {
        type: '',
        espece: '',
        client: '',
        boatName: ''
      },
      loading: false,
      atDock: false
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.loadData()
      }
    }
  },
  computed: {
    groupSpecies () {
      const species = []
      for (let i = 0; i < this.species.length; i++) {
        const exist = species.findIndex((c) => c.group === this.species[i].group)
        const cat = this.categories.find((c) => c.group === this.species[i].group)
        if (exist !== -1) {
          species[exist].species.push(this.species[i])
        } else {
          species.push({
            name: cat.name,
            group: cat.group,
            species: [this.species[i]]
          })
        }
      }
      return species
    },
    ...mapState(['user', 'species', 'categories'])
  },
  methods: {
    loadData (filters) {
      if (filters && filters.isTrusted) {
        return
      }
      this.atDock = !!(filters && filters.atDock)

      this.loading = true
      this.$store.dispatch('getMapObservations', filters)
        .then((observations) => {
          this.observations = observations
          this.loading = false
        })
    },
    setInfoWindow (obs, index) {
      const openedInfo = this.observations.find(o => o.open)
      if (openedInfo) {
        openedInfo.open = false
      }
      this.center = this.atDock && this.isValidCoords(obs.position) ? obs.position : obs.location
      this.observations.forEach((o, i) => {
        if (i === index) {
          this.infoWindowContent = {
            type: obs.type || '',
            espece: obs.espece && obs.espece.name ? obs.espece.name : '',
            client: this.getFullName(obs.client),
            boatName: obs.boatName ?? null
          }
        }
      })
      this.observations[index].open = true
    },
    getFullName (client) {
      if (client) {
        return `${client.firstName ? client.firstName : ''} ${client.lastName ? client.lastName : ''}`
      }
      return '-'
    },
    getMarkerIcon (obs) {
      const s = this.species.find((s) => s.name === (obs.espece ? obs.espece.name : '')) || {}
      switch (s.group) {
        case 5:
          return Marker5

        case 4:
          return Marker4

        case 3:
          return Marker3

        case 2:
          return Marker2

        case 1:
          return Marker1

        case 0:
          return Marker0

        default:
          return MarkerDefault
      }
    },
    isValidCoords (coords) {
      return !!(coords && coords.lat && coords.lng)
    }
  }
}
</script>

<style scoped lang="scss">
  .el-container {
    .map {
      width: 100%;
      height: 700px;

      padding-top: 1rem;
      .info-window-content {
        text-align: center;
      }
    }
  }
</style>
