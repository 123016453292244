const Vue = require('vue')

export default {
  getClients ({ commit }) {
    return Vue.api.get('clients').then(res => commit('init', { ...res.data }))
  },
  updateClient (ctx, client) {
    return Vue.api.put('client/' + client.id, client).then(res => res.data)
  },
  deleteClient (ctx, clientId) {
    return Vue.api.delete('client/' + clientId)
  },
  validateClient (ctx, clientId) {
    return Vue.api.put('client/' + clientId + '/validation').then(res => res.data)
  },
  addClient ({ commit }, client) {
    return Vue.api.post('client', client).then(res => res.data)
  }
}
