<template>
  <el-alert
    type="warning"
    class="obs-information"
    :title="alerteTitle"
    :closable="false"
    show-icon
  >
    <template v-if="showReponses">
      <el-input
        v-model="reponses"
        size="mini"
        style="width: 230px"
      />

      <el-button
        size="mini"
        type="primary"
        :loading="loading"
        plain
        @click="read(obs)"
      >
        Répondre et marquer comme lu
      </el-button>
      <el-button
        size="mini"
        type="danger"
        plain
        @click="clearResponse"
      >
        Annuler
      </el-button>
    </template>

    <template v-else>
      <el-button
        size="mini"
        type="warning"
        @click="$router.push('/observations/' + obs.id)"
      >
        Voir l'observation
      </el-button>

      <el-button
        size="mini"
        type="warning"
        :loading="loading"
        plain
        @click="read(obs)"
      >
        Marquer comme lu
      </el-button>

      <el-button
        size="mini"
        type="primary"
        :loading="loading"
        plain
        @click="showReponses = true"
      >
        Répondre
      </el-button>
    </template>
  </el-alert>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    obs: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moment,
      loading: false,
      reponses: null,
      showReponses: false
    }
  },
  computed: {
    alerteTitle () {
      if (this.obs.medias && this.obs.medias.length > 0) {
        return 'Identification photo/video'
      }
      return this.obs.informations
    }
  },
  methods: {
    read (obs) {
      const reponses = this.showReponses ? this.reponses : null
      if (confirm('Marquer l\'observation comme lu ?')) {
        this.loading = true
        this.$store.dispatch('replyObservation', { id: obs.id, reponses, read: true }).then(() => {
          this.loading = false
          this.$emit('load')
        }).catch(() => {
          this.loading = false
          this.$notify({
            title: 'Erreur',
            message: 'Impossible de marquer l\'observation comme lu',
            type: 'error'
          })
        })
      }
    },
    clearResponse () {
      this.reponses = null
      this.showReponses = false
    }
  }
}
</script>

<style lang='scss'>
  .obs-information {
    margin: 5px 0;
  }
  .obs-information .el-alert__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .obs-information .el-alert__description .el-input {
    margin-right: 10px;
  }
</style>
