<template>
  <div class="search">
    <div class="searchbar">
      <el-select v-model="filters.type" @change="change">
        <el-option label="Observation et Capture" value="" />
        <el-option value="Observation" />
        <el-option value="Capture" />
      </el-select>
      <el-select
        v-model="filters.species"
        multiple
        filterable
        placeholder="Espèces"
        :disabled="loading"
        @change="change"
        style="width:300px;"
        collapse-tags
        collapse-tags-tooltip="true"
        :max-collapse-tags="3"
      >
        <el-option-group
          v-for="item in groupSpecies"
          :key="item.group"
          :label="item.name"
          :class="{'species-options-group': true, 'selected': isSelectedElement(item, 'species')}"
          @click="clickOnTitle(item, 'species')"
        >
        <el-option
          v-for="speccy in item.species"
          :key="speccy.id"
          :label="speccy.name"
          :value="speccy.id"
        />
        </el-option-group>
      </el-select>
      <el-select
        v-model="filters.boats"
        multiple
        filterable
        placeholder="Bateaux"
        :disabled="loading"
        @change="change"
        style="width:300px;"
        collapse-tags-tooltip
        collapse-tags
      >
        <el-option-group
          v-for="client in clientBoats"
          :key="client.id"
          :label="(client.firstName.trim() + ' ' + client.lastName.trim()).toLowerCase()"
          :class="{'boat-options-group': true, 'selected': isSelectedElement(client, 'boats')}"
          @click="clickOnTitle(client, 'boats')"
        >
        <el-option
          v-for="boat in client.boats"
          :key="boat.id"
          :label="boat.name"
          :value="boat.id"
        />
        </el-option-group>
      </el-select>
    </div>
    <el-switch
      v-model="filters.atDock"
      active-text="Lieu de saisie"
      @change="change"
    />
    <el-date-picker
      v-model="filters.date"
      type="daterange"
      unlink-panels
      range-separator="à"
      start-placeholder="Début"
      end-placeholder="Fin"
      :shortcuts="shortcuts"
      @change="change"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  props: {
    loading: Boolean
  },
  data () {
    return {
      moment,
      shortcuts: [{
        text: 'Année en cours',
        value: () => [moment().startOf('year').toDate(), moment().toDate()]
      }]
    }
  },
  watch: {
    user (val) {
      if (val) {
        this.$emit('change', this.filters)
      }
    }
  },
  computed: {
    groupSpecies () {
      const species = []
      for (let i = 0; i < this.species.length; i++) {
        const exist = species.findIndex((c) => c.group === this.species[i].group)
        const cat = this.categories.find((c) => c.group === this.species[i].group)
        if (exist !== -1) {
          species[exist].species.push(this.species[i])
        } else {
          species.push({
            name: cat.name,
            group: cat.group,
            species: [this.species[i]]
          })
        }
      }
      return species
    },
    clientBoats () {
      return this.clients.map((c) => ({ ...c, boats: this.boats.filter((b) => b.clientId === c.id) }))
    },
    ...mapState(['user', 'clients', 'species', 'categories', 'boats', 'filters'])
  },
  methods: {
    change () {
      this.$store.commit('updateFilters', this.filters)
      this.$nextTick(() => this.$emit('change', this.filters))
    },
    clickOnTitle (item, key) {
      const tempFilters = { ...this.filters }
      tempFilters[key] = this.isSelectedElement(item, key)
        ? tempFilters[key].filter((el) => !item[key].map((e) => e.id).includes(el))
        : [...tempFilters[key], ...item[key].reduce((acc, el) => {
            if (!tempFilters[key].includes(el.id)) {
              acc.push(el.id)
            }
            return acc
          }, [])]
      this.$store.commit('updateFilters', tempFilters)
    },
    isSelectedElement (item, key) {
      return item[key].every((s) => this.filters[key].includes(s.id))
    }
  },
  mounted () {
    if (this.user) {
      this.$emit('change', this.filters)
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .searchbar {
    .el-select {
      margin-left: 10px;
    }
  }
}
</style>
<style lang="scss">
.species-options-group.el-select-group__wrap, .boat-options-group.el-select-group__wrap {
  .el-select-group__title {
    cursor: pointer !important;
  }
  &.selected {
    .el-select-group__title {
      color: #409eff;
      font-weight: bold;
    }
  }
}
</style>
