<template>
  <el-container>
      <el-dialog
        :modelValue="visible"
        :title="title"
        center
        @closed="$emit('update:visible', false)"
      >
        <el-form :model="form" label-position="top">
          <el-form-item
            label="Compte"
            :required="!user"
            :error="error"
          >
            <el-select
              v-model="form.super"
              size="small"
            >
              <el-option label="Limité" value="0"/>
              <el-option label="Admin" value="1"/>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Rôle"
            :required="!user"
            :error="error"
            >
            <el-select
              :disabled="form.super === '1'"
              v-model="form.role"
              size="small"
            >
              <el-option label="Consultation" value="0"/>
              <el-option label="Edition" value="1"/>
            </el-select>
          </el-form-item>
            <el-form-item
              label="Login"
              :required="true"
              :error="error"
            >
              <el-input v-model="form.login" size="small" />
            </el-form-item>
            <el-form-item
              label="Mot de passe"
              :required="!user"
              :error="error"
            >
              <el-input
                v-model="form.password"
                autocomplete="off"
                type="password"
                size="small"
              />
            </el-form-item>
            <el-form-item v-if="form.super !== '1'" label="Limité aux comptes" >
              <el-select
                v-model="form.clients"
                :disabled="allClients"
                placeholder="Choisir"
                size="small"
                filterable
                multiple
              >
                <el-option
                  v-if="allClients"
                  value="all"
                  label="Tous"
                />
                <el-option
                  v-for="c in clients"
                  :key="c.id"
                  :value="c.id"
                  :label="c.firstName + ' ' + c.lastName"
                />
              </el-select>
            </el-form-item>
            <el-form-item v-if="form.super !== '1'">
              <el-checkbox v-model="allClients">Tout sélectionner</el-checkbox>
            </el-form-item>
            <el-button @click="handleCancel">Annuler</el-button>
            <el-button type="primary" @click="handleValidate"> Valider </el-button>
        </el-form>
      </el-dialog>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AddUser',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      allClients: false,
      error: '',
      title: 'Ajouter un accès',
      form: {
        status: '1',
        role: '0',
        super: '0',
        login: '',
        password: '',
        clients: []
      }
    }
  },
  watch: {
    visible (newValue) {
      if (newValue && this.user) {
        this.form = { ...this.form, ...this.user }
        this.title = 'Modifier l\'accès'
      }
      if (!newValue) {
        this.form = {
          super: '',
          role: '',
          login: '',
          password: '',
          clients: []
        }
        this.title = 'Ajouter un accès'
      }
    },
    'form.super' (val) {
      if (val === '1') {
        this.form.role = '1'
      }
    },
    'form.clients' (val) {
      if (val && val[0] === 'all') {
        this.allClients = true
      }
    },
    allClients (value) {
      this.form.clients = value ? ['all'] : []
    }
  },
  methods: {
    handleCancel () {
      this.$emit('update:visible', false)
    },
    handleValidate () {
      this.error = null
      if (this.form.login === '' || this.form.super === '' || this.form.role === '') {
        this.error = 'Ce champ est requis'
      }
      if (this.form.super === '') {
        this.error = 'Ce champ est requis'
      }
      if (this.form.role === '') {
        this.error = 'Ce champ est requis'
      }
      if (!this.user && this.form.password === '') {
        this.error = 'Ce champ est requis'
      }
      if (!this.error) {
        if (!this.user) {
          this.$store.dispatch('addUser', this.form)
            .then((user) => {
              this.$store.commit('addUser', user)
              this.$emit('update:visible', false)
            })
            .catch((err) => {
              this.$notify({
                title: 'Erreur',
                message: 'Cet utilisateur n\'a pas pu être ajouté',
                type: 'error'
              })
              console.error(err)
            })
        } else {
          this.$store.dispatch('updateUser', { ...this.form, updatePassword: this.form.password, password: undefined })
            .then((user) => {
              this.$store.commit('updateUser', user)
              this.$emit('update:visible', false)
            })
            .catch((err) => {
              this.$notify({
                title: 'Erreur',
                message: 'Cet utilisateur n\'a pas pu être modifié',
                type: 'error'
              })
              console.error(err)
            })
        }
      }
    }
  },
  computed: {
    ...mapState(['clients'])
  }
}
</script>

<style scoped lang = "scss">
.el-dialog {
    text-align: center;
}
</style>
