<template>
  <div id="medias-obs">
    <div class="media-container">
      <h5>Média ({{medias.length}})</h5>
      <div class="medias-items" v-if="medias.length > 0">
        <template v-for="(media, i) in medias" :key="i">
          <div class="medias-item-container">
            <el-image class="media-item" v-if="media.extension !== 'mp4' && media.extension !== 'webm'" :src="obsMediaPath({id: media.mediaId, ext: media.extension})" lazy/>
            <div class="media-item video-container" v-else @click="playerAction(obsMediaPath({id: media.mediaId, ext: media.extension}), true, media.mediaId)">
              <el-icon class="el-icon-video-play"/>
            </div>
          </div>
        </template>
      </div>
      <span class="add-media" v-else>Aucun média</span>
      <el-button @click="openUpload = true" class="btn-add-photo" v-if="canEdit">Ajouter</el-button>
    </div>
    <el-dialog
      v-model="openUpload"
      title="Ajouter des média"
      width="500px"
    >
      <el-upload
          class="upload-demo"
          drag
          :action="uploadAction"
          :headers="headers"
          :on-success="uploadSuccess"
        >
          <el-icon class="el-icon-upload"></el-icon>
          <div class="el-upload__text">
            Déposer le fichier <em>ou cliquer ici</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              fichier jpg ou vidéo
            </div>
          </template>
      </el-upload>
    </el-dialog>
    <el-dialog
      v-model="openPlayer"
      width="800px"
      @close="playerAction()"
    >
      <vue3-video-player :src="playerLink" />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="download()" type="primary" icon="el-icon-download">Télécharger</el-button>
        </span>
    </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  props: {
    medias: {
      type: Array,
      default: () => []
    },
    observationId: {
      type: String,
      default: null
    }
  },
  computed: {
    uploadAction () {
      return process.env.VUE_APP_API ? process.env.VUE_APP_API + '/observation/' + this.observationId + '/upload' : ''
    },
    headers () {
      return { ...axios.defaults.headers.common, Authorization: 'Bearer ' + this.token }
    },
    canEdit () {
      return this.user && this.user.role === '1'
    },
    ...mapState(['token', 'user'])
  },
  data () {
    return {
      openUpload: false,
      openPlayer: false,
      playerLink: null,
      downloadMediaId: null
    }
  },
  methods: {
    obsMediaPath (el) {
      if (el) {
        return `${process.env.VUE_APP_API}/observations/media/${el.id}.${el.ext}?token=${this.token}`
      }
      return ''
    },
    uploadSuccess () {
      this.$store.dispatch('getObservationMedia', this.observationId)
        .then((medias) => {
          if (medias.length > this.medias.length) {
            this.$emit('update:medias', medias)
          }
        })
    },
    playerAction (link = null, visible = false, mediaId) {
      if (link && visible) {
        this.playerLink = link
        this.openPlayer = visible
        this.downloadMediaId = mediaId
      } else {
        this.playerLink = null
        this.openPlayer = false
        this.downloadMediaId = null
      }
    },
    download () {
      if (this.downloadMediaId) {
        window.open(`${process.env.VUE_APP_API}/observations/media/${this.downloadMediaId}/download?token=${this.token}`, '_blank')
      }
    }
  }
}
</script>

<style lang='scss' scoped>
#medias-obs {
  .media-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .add-media {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    h5 {
      text-align: center;
    }
    .medias-items {
      width: 100%;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      .medias-item-container {
        width: 100%;
        .media-item {
          width: 100%;
          max-width:100%;
          &.video-container {
            cursor: pointer;
            min-height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: lightgrey;
            .el-icon-video-play {
              font-size: 50px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang='scss'>
#medias-obs {
  .btn-add-photo {
    margin-top: 20px;
  }
  .el-upload {
    width: 100%;
    .el-upload-dragger {
      width: 100%;
    }
  }
}
</style>
