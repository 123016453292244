const Vue = require('vue')

export default {
  getObservation ({ commit }, observationId) {
    return Vue.api.get('observation/' + observationId).then((res) => res.data)
  },
  getObservations (ctx, data) {
    return Vue.api.post('observations', data).then((res) => res.data)
  },
  getMapObservations (ctx, data) {
    return Vue.api.post('observations/map', data).then((res) => res.data)
  },
  getObservationsMedias () {
    return Vue.api.get('observations/medias').then((res) => res.data)
  },
  getXls (context) {
    return Vue.api.get('observations/xls')
  },
  deleteObservation (ctx, observationId) {
    return Vue.api.delete('observation/' + observationId)
  },
  updateObservation (ctx, observation) {
    return Vue.api.put('observation/' + observation.id, observation).then(res => res.data)
  },
  createObservation (ctx, observation) {
    return Vue.api.post('observation', observation).then((res) => res.data)
  },
  getObservationMedia (ctx, observationId) {
    return Vue.api.get('observation/' + observationId + '/medias').then((res) => res.data)
  },
  getInformations (ctx, count = false) {
    return Vue.api.get(`observations/informations${count ? '?count=true' : ''}`)
      .then((res) => {
        if (count) {
          ctx.commit('setInformationsCount', res.data)
        } else {
          return res.data
        }
      })
  },
  replyObservation (ctx, obs) {
    return Vue.api.put(`observation/${obs.id}/read`, { reponses: obs.reponses || null })
      .then((res) => ctx.dispatch('getInformations', true).then(() => res.data))
  }
}
