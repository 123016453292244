<template>
  <el-container direction="vertical">
    <el-page-header @back="$router.push('/species-categories')" title="Retour">
      <template #content>
        <h3>Catégorie</h3>
      </template>
    </el-page-header>
    <div class="category-container" v-if="category">
      <div class="image-container">
        <Image :link="categoryGetImage(category)"/>
        <el-button v-if="categoryId" plain icon="el-icon-edit" class="edit-button" @click="uploadOpen = true">Modifier</el-button>
      </div>
      <div class="content-container">
        <div class="content menace">
          <h3>Menaces</h3>
          <div class="menaces-items">
            <Menaces v-if="category" :category="category" />
          </div>
        </div>
        <el-divider direction="vertical" />
        <div class="content species">
          <div class="header">
            <div class="title">
              <h3>Espèces ({{ filteredSpecies.length }})</h3>
              <span class="subtitle">Déplacer pour changer l'ordre</span>
            </div>
            <div class="save-button">
              <el-button v-if="orderChange" type="danger" @click="saveOrder">Enregistrer l'ordre</el-button>
              <el-button v-else type="primary" @click="open = true">Ajouter</el-button>
            </div>
          </div>
          <div class="species-items">
            <draggable
              v-model="filteredSpecies"
              @start="drag=true"
              @end="drag=false"
              item-key="id">
              <template #item="{element}">
                <div class="species-item">
                  <el-icon class="el-icon-rank"/>
                  <span class="name">{{ filteredSpecies.indexOf(element) + 1 }} - {{element.name}}</span>
                  <el-button class="species-item-edit" icon="el-icon-edit" @click="$router.push('/species-categories/' + category.group + '/' + element.id)">Modifier</el-button>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </div>
    </div>
    <UploadImage v-model:open="uploadOpen" v-if="categoryId" :path="`/image/${categoryId}/upload`" @uploaded="imageChanged"/>
    <AddSpecies v-if="category" v-model:open="open" :order="filteredSpecies.length" :group="category.group"/>
  </el-container>
</template>

<script>
import AddSpecies from '@/components/species/Add'
import UploadImage from '@/components/species/UploadImage'
import Image from '@/components/Image'
import Menaces from '@/components/species/Menaces'
import draggable from 'vuedraggable'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  components: {
    AddSpecies,
    draggable,
    Image,
    Menaces,
    UploadImage
  },
  data () {
    return {
      category: {},
      filteredSpecies: [],
      orderChange: false,
      open: false,
      uploadOpen: false,
      imageVersion: moment().unix()
    }
  },
  computed: {
    categoryId () {
      const categoryName = this.category && this.category.icon ? this.category.icon : null
      if (categoryName) {
        return categoryName.split('.')[0]
      }
      return ''
    },
    ...mapState(['categories', 'species'])
  },
  watch: {
    filteredSpecies (newVal) {
      let orderChange = false
      newVal = newVal.forEach((s, index) => {
        if (s.order !== index + 1) {
          orderChange = true
        }
      })
      this.orderChange = orderChange
    }
  },
  methods: {
    saveOrder () {
      this.$store.dispatch('updateSpeciesOrder', this.filteredSpecies)
        .then(() => {
          this.$store.dispatch('getSpecies')
          this.orderChange = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    categoryGetImage (category) {
      return category.icon && process.env.VUE_APP_API ? process.env.VUE_APP_API + '/images/' + category.icon + '?' + this.imageVersion : ''
    },
    imageChanged (add) {
      if (add) {
        this.imageVersion = moment().unix()
      }
    }
  },
  mounted () {
    if (this.$route.params.id) {
      const category = this.categories.find((c) => c.group === parseInt(this.$route.params.id))
      if (!category) {
        this.$router.push('/species-categories')
      }
      this.category = category
      this.filteredSpecies = this.species.filter((s) => s.group === category.group)
    } else {
      this.$router.push('/species-categories')
    }
  }
}
</script>

<style lang='scss' scoped>
.el-page-header {
  margin: 30px 0;
  h3 {
    margin: 0;
  }
}
.category-container {
  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    .image {
      max-width: 300px;
    }
    .edit-button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .content-container {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
    column-gap: 40px;
    align-items: top;
    .el-divider--vertical {
      height: 85%;
    }
    .content {
      & h3 {
        margin: 0;
      }
      &.menace {
        height: 100%;
      }
      .menaces-items {
        display: flex;
      }
      &.species {
        span.subtitle {
          font-size: 12px;
          color: grey;
        }
        .header {
          display: flex;
          justify-content: space-between;

        }
        .species-items {
          margin: 20px 0;
          width: 100%;
          .species-item {
            cursor: move;
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            overflow: hidden;
            &:active {
              cursor: grabbing;
              cursor: -moz-grabbing;
              cursor: -webkit-grabbing;
            }
            margin-bottom: 10px;
            border-radius: 3px;
            -webkit-box-shadow: 4px 4px 8px 1px rgba(0, 0, 0, 0.1);
            box-shadow: 4px 4px 8px 1px rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .species-item-edit {
              background-color: #b8b8b8;
              color: #fff;
              border-radius: 0 3px 3px 0;
              outline: none;
              border-color: #b8b8b8;
              &:hover {
                outline: none;
                border-color: #b8b8b8;
              }
            }
            .el-icon-rank {
              color: #b8b8b8;
              border-right: solid 1px #b8b8b846;
              padding: 10px;
            }
            span.name {
              width: 100%;
              max-width: calc(100% - 80px);
              padding: 0 10px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .el-icon-edit {
              cursor: pointer;
              padding: 10px;
              color: #fff;
              background-color: rgb(207, 207, 207);
            }
          }
        }
      }
    }
  }
}
</style>
