<template>
  <el-container id="dialog">
    <el-dialog
      :modelValue="visible"
      :title="title"
      center
      @closed="$emit('update:visible', false)">
      <el-form
        :model="form"
        label-position="top"
      >
        <el-form-item label="Compte" required :error="error.account">
          <el-select
            :disabled="!canEdit"
            v-model="form.accountType"
            placeholder="Type de compte"
            size="small"
          >
            <el-option label="Pêcheur loisir" value="1"/>
            <el-option label="Naturaliste" value="2"/>
            <el-option label="Pêcheur pro" value="3"/>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Email"
          required
          :error="error.email"
        >
          <el-input
            :disabled="!canEdit"
            v-model="form.email"
            autocomplete="off"
            size="small"
          />
        </el-form-item>
        <el-form-item
          v-if="canEdit"
          label="Mot de passe"
          :label-width="formLabelWidth"
          :required="!client"
          :error="error.password"
        >
          <el-input
            v-model="form.password"
            type="password"
            autocomplete="off"
            size="small"
          />
        </el-form-item>
        <el-form-item
        label="Prénom"
        required
        :error="error.firstName"
        :label-width="formLabelWidth"
        >
          <el-input
            :disabled="!canEdit"
            v-model="form.firstName"
            autocomplete="off"
            size="small"
          />
        </el-form-item>
        <el-form-item
          label="Nom"
          required
          :error="error.lastName"
          :label-width="formLabelWidth"
        >
          <el-input
            :disabled="!canEdit"
            v-model="form.lastName"
            autocomplete="off"
            size="small"
          />
        </el-form-item>
        <el-form-item label="Téléphone" :label-width="formLabelWidth">
          <el-input
            :disabled="!canEdit"
            v-model="form.phone"
            autocomplete="off"
            size="small"
          />
        </el-form-item>
        <el-form-item class="shipadd" v-if="form.id">
          <Boats v-if="form.id" v-model:clientId="form.id"/>
        </el-form-item>
      </el-form>
      <template #footer v-if="canEdit">
        <span class="dialog-footer">
          <el-button @click="$emit('update:visible', false)">Annuler</el-button>
          <el-button type="primary" @click="handleValidate">Valider
          </el-button>
        </span>
      </template>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import Boats from './Boats.vue'
import validator from 'validator'
export default {
  name: 'AddClient',
  components: {
    Boats
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    client: {
      type: Object,
      default: null
    }
  },
  computed: {
    canEdit () {
      return this.user && this.user.role === '1'
    },
    ...mapState(['user'])
  },
  data () {
    return {
      error: {
        accountType: null,
        email: null,
        password: null,
        firstName: null,
        lastName: null
      },
      title: 'Ajouter un compte',
      formLabelWidth: '140px',
      form: {
        id: '',
        accountType: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phone: ''
      }
    }
  },
  watch: {
    visible (newValue) {
      if (newValue && this.client) {
        this.form = { ...this.form, ...this.client }
        this.title = this.canEdit ? 'Modifier le compte' : 'Voir le compte'
      }
      if (!newValue) {
        this.form = {
          accountType: '',
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          phone: ''
        }
        this.title = 'Ajouter un compte'
      }
      this.error = {}
    }
  },
  methods: {
    handleValidate () {
      this.error = {
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        accountType: null
      }
      if (this.form.accountType === '') {
        this.error.accountType = 'Ce champ est requis'
      }
      if (this.form.firstName === '') {
        this.error.firstName = 'Ce champ est requis'
      }
      if (this.form.lastName === '') {
        this.error.lastName = 'Ce champ est requis'
      }
      if (this.form.email === '') {
        this.error.email = 'Ce champ est requis'
      }
      if (this.form.email && !validator.isEmail(this.form.email)) {
        this.error.email = 'Cet email est invalide'
      }
      if (!this.client && this.form.password === '') {
        this.error.password = 'Ce champ est requis'
      }

      if (!this.error.email && !this.error.password && !this.error.firstName && !this.error.lastName) {
        if (!this.client) {
          this.$store.dispatch('addClient', this.form)
            .then((client) => {
              this.$store.commit('addClient', client)
              this.$emit('update:visible', false)
            })
            .catch((err) => {
              this.$notify({
                title: 'Erreur',
                message: 'Cet utilisateur n\'a pas pu être ajouté',
                type: 'error'
              })
              console.error(err)
            })
        } else {
          this.$store.dispatch('updateClient', this.form)
            .then((client) => {
              this.$store.commit('updateClient', client)
              this.$emit('update:visible', false)
            })
            .catch((err) => {
              this.$notify({
                title: 'Erreur',
                message: 'Cet utilisateur n\'a pas pu être modifié',
                type: 'error'
              })
              console.error(err)
            })
        }
      }
    }
  }
}
</script>
<style lang="scss">
#dialog {
  .el-container {
    justify-content: flex-end;
  }
  .el-dialog--center {
    text-align: center;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0px;
  }
}
</style>
<style scoped lang="scss">
  .shipadd {
    text-align: left;
    padding: 0 15px 15px;
    margin-top: 15px;
    background: #fafafa;
    border: 1px solid #f1f1f1;
  }
</style>
