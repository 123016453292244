<template>
  <el-container direction="vertical" id="observations">
    <h1>Déclarations <span v-if="count > 0">({{ count }})</span></h1>
    <Filters :loading="loading" @change="load" />
    <div class="head-obs">
      <a :class="{'el-button': true, 'el-button--info': true, 'isDisabled': loading || observations.length === 0}" :href="downloadXls">Téléchargement Excel</a>
      <el-button type="primary" @click="$router.push('/observations/add')" v-if="canEdit">Ajouter une déclaration</el-button>
    </div>
    <Informations v-if="canEdit"/>
    <div class="table-container" v-if="observations.length > 0">
      <el-table
        v-loading="loading"
        :data="observations"
        :row-class-name="rowClassName"
        :default-sort="{ prop: 'createdAt', order: 'descending' }"
        style="width: 100%"
      >
        <el-table-column width="35">
          <template #default="scope">
            <i v-if="scope.row.medias > 0" class="el-icon-picture" />
          </template>
        </el-table-column>
        <el-table-column
          prop="createdAt"
          label="Date saisie"
          sortable width="160"
        >
          <template #default="scope">
            {{ datetime(scope.row.createdAt, 'DD-MM-YYYY HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column
          prop="obsAt"
          label="Date débarq."
          width="130"
        >
          <template #default="scope">
            {{ datetime(scope.row.obsAt, 'DD-MM-YYYY') }}
          </template>
        </el-table-column>
        <el-table-column
          label="Compte"
          width="150"
        >
          <template #default="scope">
            <span>{{getClientName(scope.row.clientId)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="boatName"
          label="Navire"
          width="100"
        />
        <el-table-column
          prop="type"
          label="Type"
          width="110"
        >
          <template #default="scope">
            <el-tag
              type='warning'
              v-if="scope.row.type === 'Capture'"
              >
              Capture
            </el-tag>
            <el-tag v-else>
              Observation
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="nom espèce"
          label="Espèce"
          width="150"
        >
          <template #default="scope">
            <span v-if="scope.row.especeId">{{ getSpeciesName(scope.row.especeId) }}</span>
            <span v-else-if="!scope.row.especeId && scope.row.otherEspece">{{ scope.row.otherEspece }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="nombre"
          label="Nombre"
          width="80"
        />
        <el-table-column
          prop="status"
          label="Status"
          width="80"
        >
          <template #default="scope">
            <span v-if="scope.row.type === 'Capture' && scope.row.status === '1'">vivant</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="scope"
          label="% vivants"
          width="85"
        >
        <template #default="scope" >
          <span v-if="scope.row.type === 'Capture' && parseInt(scope.row.nombre) > 0">
          {{ parseInt((parseInt(scope.row.data['nombre relaches vivants']) || 0) * 100 / (parseInt(scope.row.nombre))) }}%
          </span>
        </template>
        </el-table-column>
        <el-table-column align="right" width="250">
          <template #default="scope">
            <el-button
              size="small"
              type="primary"
              @click="handleEdit(scope.row)"
            >
            Voir les détails
            </el-button>
            <el-button
              v-if="canEdit"
              class="el-icon-delete"
              type="danger"
              size="small"
              @click="handleDelete(scope.row)"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-loading="loading"
        v-model:currentPage="page"
        background
        :page-size="25"
        layout="prev, pager, next"
        :total="count"
        hide-on-single-page
        @update:currentPage="updatePage"
      />
    </div>
    <template v-else-if="observations.length < 1 && loading">
      <p class="table-message">Chargement...</p>
    </template>
    <template v-else>
      <p class="table-message">Aucune donnée</p>
    </template>
  </el-container>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { mapState } from 'vuex'
import Filters from './Filters'
import Informations from './Informations.vue'

export default {
  components: {
    Filters,
    Informations
  },
  data () {
    return {
      page: 1,
      count: null,
      visible: false,
      observation: null,
      observations: [],
      filters: {},
      loading: false
    }
  },
  computed: {
    downloadXls () {
      return `${axios.defaults.baseURL}observations/xls?token=${this.token}`
    },
    canEdit () {
      return this.user && this.user.role === '1'
    },
    ...mapState(['clients', 'user', 'token', 'species'])
  },
  methods: {
    handleDelete (row) {
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer cette observation ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteObservation', row.id)
            .then(() => this.resetPage())
        })
        .catch((err) => {
          this.$notify({
            title: 'Erreur',
            message: 'Cette observation n\'a pas pu être supprimée',
            type: 'error'
          })
          console.error(err)
        })
    },
    handleEdit (row) {
      this.observation = row
      this.$router.push('/observations/' + row.id)
    },
    datetime (ts, format) {
      return moment.unix(ts).format(format)
    },
    getClientName (clientId) {
      const client = this.clients.find((c) => c.id === clientId)
      if (client) {
        return client.firstName + ' ' + client.lastName
      }
      return '-'
    },
    load (filters) {
      if (filters && filters.isTrusted) {
        return
      }

      this.filters = filters || this.filters
      this.filters.page = this.page
      this.$store.dispatch('getObservations', this.filters).then((res) => {
        this.observations = res.observations
        this.count = res.count
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.$notify({
          title: 'Erreur',
          message: 'Ces observations n\'ont pas pu être chargées',
          type: 'error'
        })
        console.error(err)
      })
    },
    resetPage () {
      this.page = 1
      this.load()
    },
    rowClassName ({ row }) {
      if (row.type === 'Capture' && parseInt(row.nombre) > 0) {
        const percent = (parseInt(row.data['nombre relaches vivants']) || 0) * 100 / (parseInt(row.nombre))
        if (percent > 50 && percent <= 75) {
          return 'pending'
        } else if (percent <= 50) {
          return 'danger'
        }
      }
    },
    updatePage (page) {
      this.page = page
      this.load()
    },
    getSpeciesName (speciesId) {
      const species = this.species.find((s) => s.id === speciesId)
      if (species) {
        return species.name.split('(')[0]
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .el-container {
    .head-obs {
      display: flex;
      justify-content: space-between;
      padding: 25px 0;
      .el-button + .el-button {
        text-decoration: none;
      }
      a.isDisabled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.3;
      }
    }
    .el-pagination {
      padding: 1rem;
      text-align: center;
    }
    .table-message {
      margin-top: 15px;
      text-align: center;
    }
  }
</style>
<style lang="scss">
  #observations {
    .el-table .pending {
    background: oldlace;
  }
    .el-table .danger {
    background: #fde8e6;
    }
  }
</style>
