export default {
  addUser (state, user) {
    state.users.push(user)
  },
  deleteUser (state, userId) {
    state.users = state.users.filter((u) => u.id !== userId)
  },
  updateUser (state, user) {
    for (let i = 0; i < state.users.length; i++) {
      if (state.users[i].id === user.id) {
        state.users[i] = user
      }
    }
  }
}
