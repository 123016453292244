const Vue = require('vue')

export default {
  login ({ commit }, data) {
    return Vue.api.post('login', data)
      .then((res) => commit('init', { token: res.data }))
  },
  getUser ({ commit }) {
    return Vue.api.get('me').then((res) => commit('init', { user: res.data }))
  },
  getUsers ({ commit }) {
    return Vue.api.get('users').then((res) => commit('init', { users: res.data }))
  },
  getHistory ({ commit }, data) {
    return Vue.api.get('history?search=' + (data.search || '') + '&page=' + (data.page || '')).then((res) => res.data)
  },
  addUser ({ commit }, user) {
    return Vue.api.post('user', user).then((res) => res.data)
  },
  deleteUser (ctx, userId) {
    return Vue.api.delete('user/' + userId)
  },
  updateUser (ctx, user) {
    return Vue.api.put('user/' + user.id, user).then(res => res.data)
  }
}
