<template>
  <div id="app" v-loading="loading">
    <el-menu
      :router="true"
      :default-active="active"
      class="el-menu-demo"
      mode="horizontal"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-menu-item index="/">Comptes</el-menu-item>

      <el-menu-item index="/observations">
        <span class="with-badge">
          <el-badge :value="informationsCount > 0 ? informationsCount : null" type="danger">
            Déclarations
          </el-badge>
        </span>
      </el-menu-item>

      <el-menu-item index="/medias">Médias</el-menu-item>
      <el-menu-item index="/map">Carte</el-menu-item>
      <el-menu-item v-if="isAdmin" index="/species-categories">Espèces</el-menu-item>
      <el-menu-item v-if="isAdmin" index="/settings">Réglages</el-menu-item>
      <el-menu-item v-if="isAdmin" index="/history">Historique</el-menu-item>
      <div class="flex-grow" />
      <el-menu-item v-if="token">
        <el-button type="danger" @click="logout">Déconnexion</el-button>
      </el-menu-item>
    </el-menu>
    <router-view class="container"/>
  </div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  data () {
    return {}
  },
  created () {
    const token = this.$cookies.get('token')
    if (token) {
      this.$store.commit('init', { token: token.replace('/', '') })
    } else {
      this.$router.push('/login').catch(() => {})
    }
  },
  computed: {
    active () {
      return '/' + (this.$route.path || '').substring(1).split('/').shift()
    },
    isAdmin () {
      return this.user && this.user.super === '1'
    },
    ...mapState(['observation', 'user', 'token', 'loading', 'informationsCount'])
  },
  watch: {
    token (value, old) {
      if (value) {
        this.$cookies.set('token', value.replace('/', ''), { path: '/' })
        setTimeout(() => this.$store.dispatch('load'), 50)

        if (this.$route.path.startsWith('/login')) {
          this.$nextTick(() => this.$router.push('/'))
        }
      } else if (old) {
        this.$cookies.remove('token')
        this.$nextTick(() => this.$router.push('/login'))
      }
    }
  },
  methods: {
    logout () {
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  }
}

</script>

<style lang="scss">
  body,html {
    margin: 0;
    padding: 0;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    .container {
      max-width: 1350px;
      margin: 0 auto;
      h1 {
        text-align: center;
        font-size: 2em;
      }
    }
    .el-menu {
      display: flex;
      .flex-grow {
        flex-grow: 1;
      }
    }
    .el-pagination.is-background {
      .btn-prev, .btn-next {
        padding: 0 8px!important;
      }
    }
  }
  .with-badge {
    .el-badge__content {
      top: 15px!important;
      right: 4px!important;
    }
  }

  @media screen and (max-width: 1390px) {

    .el-container {
      margin: 0 20px!important;
    }
  }
</style>
