import _ from 'lodash'
import axios from 'axios'
import user from './user'
import observation from './observation'
import client from './client'
import species from './species'
import category from './category'
import boat from './boat'
import settings from './settings'

const Vue = require('vue')

axios.defaults.baseURL = (process.env.VUE_APP_API || 'https://v2.echosea.fr/api/backend') + '/'
axios.defaults.timeout = 60000

Vue.api = axios.create()
export default _.merge(user, observation, client, species, category, boat, settings)
