<template>
  <el-container direction="vertical" v-if="currentSpecies">
    <el-page-header @back="$router.push('/species-categories/'+ $route.params.categoryId)" title="Retour">
        <template #content>
          <h3>Espèce</h3>
        </template>
      </el-page-header>
      <div id="species-container">
        <template v-if="!isEditting">
          <h1>{{ getName(currentSpecies.name) }}</h1>
          <span class="latin-name">
            {{ getName(currentSpecies.name, 'latin') }}
            <template v-if="currentSpecies.code">&mdash; {{ currentSpecies.code }}</template>
          </span>
          <p class="forbidden">
            <el-tag v-if="currentSpecies.forbidden === '1'" type="danger">Interdit à la pêche</el-tag>
            <el-tag v-else type="success">Autorisé la pêche</el-tag>
          </p>
          <div class="iucn" v-if="currentSpecies.iucn">
            <Image :link="getImageUrl('iucn', currentSpecies.iucn + '_IUCN')"/>
          </div>
          <el-button class="edit" type= "primary" @click="isEditting = true">Modifier</el-button>
        </template>
        <template v-else>
          <div class="input-container">
            <el-input type="text" :class="{'name-edit': true, 'error': errorName}" v-model="currentSpecies.name" />
            <span class="error-name" v-if="errorName">{{ errorName }}</span>
            <div class="save">
              <el-button type="success" @click="save">Sauvegarder</el-button>
              <el-button type="danger" @click="cancel">Annuler</el-button>
            </div>
          </div>
        </template>

        <el-row v-if="isEditting">
          <el-col :span="8">
            <div class="iucn">
              <span>Iucn:</span>
              <el-select v-model="currentSpecies.iucn" placeholder="Iucn" label="Iucn">
                <el-option value="null">Aucun</el-option>
                <el-option
                  v-for="(value, key) in iucnList"
                  :key="key"
                  :value="key"
                  :label="value"
                >
                  ({{ key }}) {{ value }}
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="iucn">
              <span>Code espèce:</span>
              <el-input v-model="currentSpecies.code" />
            </div>
          </el-col>
          <el-col :span="8">
            <div class="iucn">
              <span>Interdit à la pêche :</span>
              <el-select v-model="currentSpecies.forbidden">
                <el-option label="Oui" value="1" />
                <el-option label="Non" value="0" />
              </el-select>
            </div>
          </el-col>
        </el-row>

        <div class="header">
          <div class="image-container">
            <h3>Photo</h3>
            <Image v-model:link="link" v-model:emptyImage="emptyImage"/>
            <template v-if="isEditting">
              <el-button type="danger" @click="photoDelete()" v-if="!emptyImage">Supprimer</el-button>
              <el-button type="primary" @click="upload()">{{ emptyImage ? 'Ajouter' : 'Modifier la photo'}}</el-button>
            </template>
          </div>
          <div class="image-container">
            <h3>Illustration</h3>
            <Image v-model:link="linkIllustration" v-model:emptyImage="emptyIllustration"/>
            <template v-if="isEditting">
              <el-button type="danger" @click="photoDelete(true)" v-if="!emptyIllustration">Supprimer</el-button>
              <el-button type="primary" @click="upload(true)">{{ emptyIllustration ? 'Ajouter' : 'Modifier l\'illustration'}}</el-button>
            </template>
          </div>
        </div>
        <div class="property-group">
          <div class="property-container">
            <div class="property-items">
              <h3>Taille moyenne</h3>
              <div>
                <div class="property-item">
                  <template v-if="isEditting">
                    <el-input v-model="currentSpecies.sizeChild"/>
                  </template>
                  <template v-else-if="currentSpecies.sizeChild ">
                    <p>{{ currentSpecies.sizeChild }}</p>
                  </template>
                  <template v-else>
                    <p><em>Aucune information</em></p>
                  </template>
                </div>
              </div>
              <h3>Maturité sexuelle</h3>
              <div>
                <div class="property-item">
                  <template v-if="isEditting">
                    <el-input v-model="currentSpecies.sizeMaturity"/>
                  </template>
                  <template v-else-if="currentSpecies.sizeMaturity ">
                    <p>{{ currentSpecies.sizeMaturity }}</p>
                  </template>
                  <template v-else>
                    <p><em>Aucune information</em></p>
                  </template>
                </div>
              </div>
              <h3>Taille adulte</h3>
              <div>
                <div class="property-item">
                  <template v-if="isEditting">
                    <el-input v-model="currentSpecies.sizeAdult"/>
                  </template>
                  <template v-else-if="currentSpecies.sizeAdult ">
                    <p>{{ currentSpecies.sizeAdult }}</p>
                  </template>
                  <template v-else>
                    <p><em>Aucune information</em></p>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="property-container" v-for="(property, i) in properties" :key="i">
            <div class="property-items">
              <h3>{{ property.name }}</h3>
              <div v-if="currentSpecies[property.field] && currentSpecies[property.field].length > 0">
                <div v-for="(item, j) in currentSpecies[property.field]" :key="j" class="property-item">
                  <template v-if="isEditting">
                      <el-input v-model="currentSpecies[property.field][j]"/>
                      <el-icon class="el-icon-circle-close" @click="currentSpecies[property.field].splice(j, 1)"/>
                  </template>
                  <template v-else>
                    <p>{{ item }}</p>
                  </template>
                </div>
              </div>
              <p v-else>Aucune information</p>
              <el-button v-if="isEditting" type="info" @click="addPropertyField(property.field)">Ajouter une ligne</el-button>
            </div>
          </div>
        </div>
      </div>
      <UploadImage v-model:open="uploadOpen" :path="uploadPath" @uploaded="photoAdded"/>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import Image from '@/components/Image'
import moment from 'moment-timezone'
import UploadImage from '@/components/species/UploadImage'
export default {
  components: {
    Image,
    UploadImage
  },
  data () {
    return {
      currentSpecies: null,
      isEditting: false,
      emptyImage: false,
      emptyIllustration: false,
      link: null,
      linkIllustration: null,
      uploadOpen: false,
      uploadPath: '',
      properties: [
        { name: 'Description', field: 'description' },
        { name: 'Protection', field: 'protection' },
        { name: 'Répartition', field: 'repartition' },
        { name: 'Reproduction', field: 'reproduction' },
        { name: 'Autres noms', field: 'noms' },
        { name: 'Sources', field: 'sources' }
      ],
      errorName: null,
      iucnList: {
        // NE: 'NOT EVALUATED',
        DD: 'DATA DEFICIENT',
        LC: 'LEAST CONCERN',
        NT: 'NEAR THREATENED',
        VULNERABLE: 'VULNERABLE',
        EN: 'ENDANGERED',
        CR: 'CRITICALLY ENDANGERED'
        // EW: 'EXTINCT IN THE WILD',
        // EX: 'EXTINCT'
      }
    }
  },
  computed: {
    ...mapState(['species'])
  },
  methods: {
    save () {
      this.errorName = null
      if (!this.currentSpecies.name.length > 0) {
        this.errorName = 'Ce champ est requis'
        return
      }

      if (!this.errorName) {
        this.$store.dispatch('updateSpecies', this.currentSpecies)
          .then((res) => {
            this.$store.commit('updateSpecies', res)
            this.currentSpecies = res
          })
        this.isEditting = false
      }
    },
    cancel () {
      this.getLocal()
      this.isEditting = false
    },
    getLocal () {
      const local = this.species.find((s) => s.id === this.$route.params.id)
      if (local) {
        this.currentSpecies = JSON.parse(JSON.stringify(local))
        this.getImageUrl()
        this.getImageUrl('id', 'illustration_')
      } else {
        this.$router.push('/species-categories/' + this.$route.params.categoryId)
      }
    },
    getImageUrl (el = 'id', file = null) {
      let localFile = this.currentSpecies[el]
      if (el === 'iucn') {
        localFile = file
      }
      if (file === 'illustration_') {
        localFile = file + localFile
      }
      const link = this.currentSpecies[el] && process.env.VUE_APP_API ? process.env.VUE_APP_API + '/images/' + localFile + '.jpg?' + moment().unix() : ''
      if (el === 'id' && !file) {
        this.link = link
        this.emptyImage = false
      } else if (el === 'id') {
        this.linkIllustration = link
        this.emptyIllustration = false
      } else {
        return link
      }
    },
    getName (name, format = '') {
      if (format === 'latin') {
        return name.substring(name.indexOf('(') !== -1 ? name.indexOf('(') : name.length)
      }
      return name.indexOf('(') !== -1 ? name.substring(0, name.indexOf('(')) : name
    },
    photoDelete (illustration = false) {
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer la photo ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteSpeciesPhoto', (illustration ? 'illustration_' : '') + this.currentSpecies.id)
            .then(() => {
              if (illustration) {
                this.getImageUrl('id', 'illustration_')
              } else {
                this.getImageUrl()
              }
            })
        })
        .catch(() => {
        })
    },
    photoAdded (add) {
      if (add) {
        this.getImageUrl()
        this.getImageUrl('id', 'illustration_')
      }
    },
    upload (illustration = false) {
      this.uploadPath = `/image/${(illustration ? 'illustration_' : '') + this.currentSpecies.id}/upload`
      this.uploadOpen = true
    },
    addPropertyField (field) {
      this.currentSpecies[field] = this.currentSpecies[field] || []
      this.currentSpecies[field].push('')
    }
  },
  mounted () {
    if (this.$route.params.categoryId && this.$route.params.id) {
      this.getLocal()
    } else {
      this.$router.push('/species-categories')
    }
  }
}
</script>

<style lang='scss' scoped>
.el-page-header {
  margin: 30px 0;
  h3 {
    margin: 0;
  }
}
#species-container {
  margin-bottom: 30px;
  position: relative;
  & > .el-button {
    position: absolute;
    top: 30px;
    right: 0;
  }
  .forbidden {
    text-align: center;
  }
  .latin-name {
    display: block;
    text-align: center;
    margin-top: -25px;
    font-style: italic;
  }
  .input-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    gap: 10px;
    .save {
      display: flex;
      align-items: center;
    }
    .name-edit {
      text-align: center;
      font-size: 2em;
      margin-block-start: 0.83em;
      margin-block-end: 0.83em;
      font-weight: bold;
      font-family: Avenir, Helvetica, Arial, sans-serif;
      border-radius: 4px;
      &.error {
        border: solid 2px #f56c6c;
        outline: none;
      }
    }
    .error-name {
      position: absolute;
      bottom: 3px;
      font-style: italic;
      color: #f56c6c;
      font-size: 14px;
    }
  }
  .header {
    padding-top: 25px;
    display: flex;
    gap: 30px;
    .image-container {
      width: 50%!important;
      min-height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      h3 {
        text-align: center;
      }
      .el-image {
        width: 100%;
      }
    }
  }
  .iucn {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
    .el-input {
      max-width: 120px;
    }
  }
  .property-group {
    margin-top: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    gap: 40px;
    & > .property-container {
      width: 100%;
    }
  }
  .property-container {
    box-sizing: border-box;
    width: 50%;
    padding: 10px 30px;
    border-radius: 5px;
    -webkit-box-shadow: 4px 4px 8px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 8px 1px rgba(0, 0, 0, 0.1);
    .property-items {
      width: 100%;
      margin: 0 auto;
      .property-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        p {
          margin: 0;
        }
        .el-input {
          width: calc(100% - 30px);
        }
        .el-icon {
          font-size: 18px;
          color: red;
        }
      }
    }
  }
}

</style>
<style lang='scss'>
  #species-container {
    .iucn {
      .el-image {
        display: flex;
        justify-content: center;
        img {
          max-height: 150px;
        }
      }
    }
  }
</style>
