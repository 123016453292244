<template>
  <el-container direction="vertical">
    <h1>Réglages</h1>
    <el-form
      v-if="settings"
      label-width="190px"
      align="center"
      label-position="left"
    >
      <el-form-item label="Délai d'affichage des cartes">
        <el-select
          v-model="settings.frequence"
          class="m-2"
          placeholder="Select"
          size="large"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Version application">

        <el-input
          v-model="settings.version"
          class="version-input"
        />
        <el-button
          type="primary"
          icon="el-icon-circle-check"
          :disabled="!settingsChange"
          @click="save"
        >
          Enregistrer
        </el-button>
      </el-form-item>
    </el-form>
    <el-divider>Accès au backoffice</el-divider>
    <div class="searchandadd">
      <el-input
        class="searchbar"
        type="text"
        placeholder="Recherche"
        v-model="search"
        clearable
       />
      <el-button
        class="addbtn"
        type="primary"
        icon="el-icon-circle-plus"
        @click="addUser">Ajouter</el-button>
    </div>
    <AddUser v-model:visible="visible" :user="localUser"/>
    <el-table
      :data="filteredUsers"
      :default-sort="{ prop: 'status', order: 'ascending' }"
      style="width: 100%"
    >
      <el-table-column
        prop="login"
        label="Login"
        sortable width="180"
      />
      <el-table-column
        prop="clients"
        label="Comptes visibles"
        sortable
      >
        <template #default="scope">
          <el-tag type="primary plain" v-if="scope.row.super === '1'">
            Tous (Admin)
          </el-tag>
          <div v-else-if="scope.row.clients.length === 1 && scope.row.clients[0] === 'all'">
            Tous
          </div>
          <div v-else>
            {{ scope.row.clients.length }} compte{{scope.row.clients.length > 1 ? 's' : ''}}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        label="Status"
        sortable
      >
        <el-tag type="success plain" size="small">Actif</el-tag>
      </el-table-column>
      <el-table-column
        prop="role"
        label="Rôle"
        sortable
      >
        <template #default="scope">
          <el-tag type="success plain" v-if="scope.row.role === '1'"
            >Edition</el-tag>
          <el-tag type="primary plain" v-if="scope.row.role === '0'"
            >Consultation</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="right" width="250px">
        <template #default="scope">
          <el-button
            v-if="scope.row.status === '0'"
            type="warning"
            size="small" @click="rowValidate(scope.row)"
          >
          Activer
          </el-button>
          <el-button
            type="danger"
            size="small"
            v-if="scope.row.status === '1'"
            @click="rowDisable(scope.row)"
          >
          Suspendre
          </el-button>
          <el-button
            class="el-icon-edit"
            size="small"
            @click="handleEdit(scope.row)"
          >
          </el-button>
          <el-button
            class="el-icon-delete"
            type="danger"
            size="small"
            @click="handleDelete(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import AddUser from './AddUser.vue'
export default {
  components: {
    AddUser
  },
  data () {
    return {
      localUser: null,
      visible: false,
      search: '',
      settingsChange: false,
      settings: null,
      options: [
        {
          value: 1,
          label: '1 journée'
        },
        {
          value: 7,
          label: '7 jours'
        },
        {
          value: 14,
          label: '14 jours'
        },
        {
          value: 21,
          label: '21 jours'
        },
        {
          value: 30,
          label: '1 mois'
        },
        {
          value: 60,
          label: '2 mois'
        },
        {
          value: 90,
          label: '3 mois'
        },
        {
          value: 365,
          label: '1 an'
        }
      ]
    }
  },
  watch: {
    settings: {
      handler (val, old) {
        if (val && old) {
          this.settingsChange = true
        }
      },
      deep: true
    },
    user (val) {
      if (val) {
        this.load()
      }
    }
  },
  mounted () {
    if (this.user) {
      this.load()
    }
  },
  methods: {
    addUser () {
      this.localUser = null
      this.visible = true
    },
    rowValidate (row) {
      this.$store.dispatch('updateUser', { ...row, status: '1' })
        .then((user) => {
          this.$store.commit('updateUser', user)
        })
        .catch((err) => {
          this.$notify({
            title: 'Erreur',
            message: 'Cet utilisateur n\'a pas pu être modifié',
            type: 'error'
          })
          console.error(err)
        })
    },
    rowDisable (row) {
      this.$store.dispatch('updateUser', { ...row, status: '0' })
        .then((user) => {
          this.$store.commit('updateUser', user)
        })
        .catch((err) => {
          this.$notify({
            title: 'Erreur',
            message: 'Cet utilisateur n\'a pas pu être modifié',
            type: 'error'
          })
          console.error(err)
        })
    },
    handleEdit (row) {
      this.localUser = row
      this.visible = true
    },
    handleDelete (row) {
      this.$confirm(
        'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
        'Attention',
        {
          confirmButtonText: 'Supprimer',
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }
      )
        .then(() => {
          this.$store.dispatch('deleteUser', row.id)
            .then(() => {
              this.$store.commit('deleteUser', row.id)
            })
        })
        .catch((err) => {
          this.$notify({
            title: 'Erreur',
            message: 'Cet utilisateur n\'a pas pu être supprimé',
            type: 'error'
          })
          console.error(err)
        })
    },
    load () {
      this.$store.dispatch('getSettings').then((settings) => {
        this.settings = settings
      }).catch((err) => {
        this.loadingData = false
        this.$notify({
          title: 'Erreur',
          message: 'Ces données n\'ont pas pu être chargées',
          type: 'error'
        })
        console.error(err)
      })
    },
    save () {
      this.$store.dispatch('updateSettings', { ...this.settings, version: parseFloat(this.settings.version) }).then(() => {
        this.settingsChange = false
        this.$notify({
          title: 'Réglages modifiés',
          message: 'Réglages modifiés avec succès',
          type: 'success'
        })
      }).catch((err) => {
        this.$notify({
          title: 'Erreur',
          message: 'Les réglages n\'ont pas pu être modifiés',
          type: 'error'
        })
        console.error(err)
      })
    }
  },
  computed: {
    filteredUsers () {
      return this.users.filter((u) => {
        if (this.search.length === 0) {
          return true
        }
        return u.login.toLowerCase().match(this.search.toLowerCase())
      })
    },
    ...mapState(['users', 'user'])
  }
}

</script>
<style lang="scss" scoped>
  .el-container {
    .searchandadd {
      display: flex;
      justify-content: space-between;
      .searchbar {
        width: 50%;
      }
    }
    .version-input {
      max-width: 193px;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    .el-form {
      margin: 0 auto;
      width: 550px;
    }
  }
</style>
