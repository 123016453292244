<template>
  <div class="image">
    <el-icon v-if="emptyImage" class="el-icon-picture"/>
    <el-image v-else-if="link" :src="link" fit="contain" lazy @error="$emit('update:emptyImage', true)"/>
  </div>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      default: ''
    },
    emptyImage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang='scss' scoped>
.image {
  width: 100%;
  & > .el-icon {
    width: 100%;
    color: lightgrey;
    font-size: 5rem;
  }
}
</style>
