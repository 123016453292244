<template>
  <el-dialog
    :model-value="open"
    title="Ajouter une espèce"
    @close="$emit('update:open', false)"
  >
    <el-form>
      <el-form-item label="Nom" :error="error">
        <el-input v-model="form.name" :error="error"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="saveSpecies">
        Ajouter
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    order: Number,
    group: Number
  },
  data () {
    return {
      form: {
        name: ''
      },
      error: null
    }
  },
  methods: {
    saveSpecies () {
      this.error = null
      if (this.form.name === '') {
        this.error = 'Ce champ est requis'
        return
      }
      if (!this.error) {
        this.$store.dispatch('createSpecies', { ...this.form, order: this.order + 1, group: this.group })
          .then((res) => {
            this.$router.push('/species-categories/' + this.group + '/' + res.id)
          })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
