import { createRouter, createWebHashHistory } from 'vue-router'
import Clients from '../components/Clients.vue'
import Observations from '../components/Observations.vue'
import Medias from '../components/Medias.vue'
import Map from '../components/Map.vue'
import Settings from '../components/Settings.vue'
import History from '../components/History.vue'
import AddObservation from '../components/AddObservation.vue'
import Login from '@/components/Login.vue'
import Categories from '@/components/species/Categories.vue'
import Category from '@/components/species/Category.vue'
import Species from '@/components/species/Species.vue'

import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()

const routes = [
  {
    path: '/',
    name: 'Clients',
    component: Clients
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/observations/:id',
    name: 'AddObservation',
    component: AddObservation
  },
  {
    path: '/observations',
    name: 'Observations',
    component: Observations
  },
  {
    path: '/medias',
    name: 'Medias',
    component: Medias
  },
  {
    path: '/map',
    name: 'Carte',
    component: Map
  },
  {
    path: '/settings',
    name: 'Réglages',
    component: Settings
  },
  {
    path: '/history',
    name: 'Historique',
    component: History
  },
  {
    path: '/species-categories',
    name: 'Categories',
    component: Categories
  },
  {
    path: '/species-categories/:id',
    name: 'Categorie',
    component: Category
  },
  {
    path: '/species-categories/:categoryId/:id',
    name: 'Espece',
    component: Species
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && !cookies.get('token')) next({ path: '/login' })
  else next()
})

export default router
