const Vue = require('vue')

export default {
  getSettings ({ commit }) {
    return Vue.api.get('settings').then((res) => res.data)
  },
  updateSettings ({ commit }, data) {
    return Vue.api.put('settings', data).then((res) => res.data)
  }
}
