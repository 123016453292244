const Vue = require('vue')

export default {
  getSpecies ({ commit }) {
    return Vue.api.get('species').then(res => commit('init', { species: res.data }))
  },
  updateSpeciesOrder ({ commit }, data) {
    return Vue.api.put('species/order', { species: data })
  },
  updateSpecies ({ commit }, data) {
    return Vue.api.put('species/' + data.id, data).then((res) => res.data)
  },
  createSpecies ({ commit }, species) {
    return Vue.api.post('species', species).then((res) => {
      commit('addSpecies', res.data)
      return res.data
    })
  },
  deleteSpeciesPhoto (ctx, speciesId) {
    return Vue.api.delete('species/' + speciesId + '/photo')
  },
  addSpeciesPhoto (ctx, speciesId) {
    return Vue.api.post('species/' + speciesId + '/photo')
  }
}
