<template>
  <el-container direction="vertical" id="categories">
    <h1>Catégories</h1>
    <div class="search">
      <el-input
        class="searchbar"
        type="text"
        placeholder="Recherche"
        v-model="search"
        clearable
      />
    </div>
    <template v-if="!search">
      <div class="categories-container">
        <div v-for="(category, i) in categories" class="category" :key="i">
          <div class="img-container">
            <Image :link="categoryGetImage(category)" @click="$router.push('/species-categories/' + category.group)"/>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="filteredSpecies.length > 0" class="search-categories-container">
        <div v-for="(item, i) in filteredSpecies" :key="i" class="search-categories-item">
          <el-divider content-position="left"><h3>{{ getTitle(item.group) }}</h3></el-divider>
          <div v-for="(species, i) in item.species" :key="i">
            <el-link @click.prevent="$router.push('/species-categories/' + item.group + '/' + species.id)">{{ species.name }}</el-link>
          </div>
        </div>
      </div>
      <span class="no-results" v-else>Aucun résultat</span>
    </template>
  </el-container>
</template>

<script>
import { mapState } from 'vuex'
import Image from '@/components/Image.vue'
import moment from 'moment'

export default {
  components: {
    Image
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    filteredSpecies () {
      const filter = []
      if (this.search.length > 0) {
        for (let i = 0; i < this.species.length; i++) {
          if (this.species[i].name.toLowerCase().match(this.search.toLowerCase())) {
            const exist = filter.findIndex((c) => c.group === this.species[i].group)
            if (exist !== -1) {
              filter[exist].species.push(this.species[i])
            } else {
              filter.push({ group: this.species[i].group, species: [this.species[i]] })
            }
          }
        }
      }
      return filter
    },
    ...mapState(['species', 'categories'])
  },
  methods: {
    getTitle (group) {
      const cat = this.categories.find((c) => c.group === group)
      return cat.name || ''
    },
    categoryGetImage (category) {
      return category.icon && process.env.VUE_APP_API ? process.env.VUE_APP_API + '/images/' + category.icon + '?' + moment().unix() : ''
    }
  }
}
</script>

<style lang='scss' scoped>
#categories {
  .search {
    display: flex;
    justify-content: center;
    .searchbar {
      width: 50%;
    }
  }
  .categories-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .category {
      margin-top: 60px;
      width: 30%;
      overflow: hidden;
      .img-container {
        width: 50%;
        overflow: hidden;
        margin: 0 auto;
        cursor: pointer;
      }
      p {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
  .search-categories-container {
    margin-top: 10px;
    .search-categories-item .el-link{
      margin-left: 40px;
    }
  }
  .no-results {
    margin-top: 10px;
  }
}
</style>
