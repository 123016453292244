const Vue = require('vue')

export default {
  updateBoats ({ commit }, data) {
    return Vue.api.put('/client/' + data.clientId + '/boats', data).then((res) => commit('updateBoats', { clientId: data.clientId, boats: res.data }))
  },
  deleteBoat ({ commit }, data) {
    return Vue.api.delete('/client/' + data.clientId + '/boats/' + data.boatId).then(() => commit('deleteBoat', data.boatId))
  }
}
