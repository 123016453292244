import { createStore } from 'vuex'
import api from './api'
import mutation from './mutation'
import moment from 'moment'
const Vue = require('vue')

export default createStore({
  state: {
    token: null,
    user: null,
    clients: [],
    users: [],
    boats: [],
    categories: [],
    species: [],
    loading: false,
    filters: {
      type: '',
      date: [moment().startOf('year').toDate(), moment().toDate()],
      species: [],
      boats: [],
      atDock: false
    },
    informationsCount: 0
  },
  mutations: {
    ...mutation,
    updateFilters (state, filters) {
      state.filters = filters
    },
    setInformationsCount (state, count) {
      state.informationsCount = count
    },
    init (state, data) {
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          state[key] = data[key]
        }
      }
    },
    logout (state) {
      state.token = null
      state.user = null
      state.clients = []
      state.users = []
      state.boats = []
      state.categories = []
      state.species = []
      delete Vue.api.defaults.headers.common.Authorization
    }
  },
  actions: {
    ...api,
    load ({ commit, state, dispatch }) {
      Vue.api.defaults.headers.common.Authorization = 'Bearer ' + state.token
      commit('init', { loading: true })
      setImmediate(async () => {
        try {
          await dispatch('getUser')
          await Promise.all([
            dispatch('getUsers'),
            dispatch('getClients'),
            dispatch('getCategories'),
            dispatch('getSpecies'),
            dispatch('getInformations', true)
          ])
        } catch (err) {
          commit('init', { loading: false })
          return commit('logout')
        }
        commit('init', { loading: false })
      })
    }
  }
})
